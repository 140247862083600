import CarroModel from "../models/Carro";
import { firebaseDatabase, firebaseStorage } from "../utils/firebase.utils";
import ModeloModel from "../models/Modelo";
import MarcaModel from "../models/Marca";

export default class CarService {

    public static totalDeCarros(): Promise<number> {
        return firebaseDatabase.collection('carros').where('deleted','==',false).get().then((sn) => {
            return sn.size;
        });
    }

    public static async recuperarCarro(id: string): Promise<CarroModel | undefined> {
        let data: any[] = []
        return await firebaseDatabase.collection('carros').where('id', '==', id).get().then((doc) => {
            if(doc?.size > 0) {
                return doc?.docs[0]?.data() as CarroModel
            }
            console.log(`Failed to fetch car ${id}`)
            return undefined
        })
    }

    public static async listarCarros() {
        let data: any[] = []
        await firebaseDatabase.collection('carros').where('deleted','==',false).get().then((sn) => {
            sn.forEach(doc => {
                data.push(doc.data())
            })
        })
        return data;
    }

    public static async listarMarcas() {
        let data: any[] = []
        await firebaseDatabase.collection('marcas').get().then((sn) => {
            sn.forEach(doc => {
                data.push(doc.data())
            })
        })
        return data;
    }

    public static async listarModelos() {
        let data: any[] = []
        await firebaseDatabase.collection('modelos').get().then((sn) => {
            sn.forEach(doc => {
                data.push(doc.data())
            })
        })
        return data;
    }

    public static async removerCarro(carro: CarroModel) {
        if (carro.id != null) {
            let docs = await firebaseDatabase.collection('carros').where('id', '==', carro.id).get();
            for (const doc of docs.docs) {
                try {
                    // await this.removerImagens(doc.data());
                    // await doc.ref.delete();
                    await doc.ref.set({...carro, deleted: true});
                } catch (err) {
                    console.error(err)
                }
            }
        }
    }

    public static async removerImagens(carro: any) {
        for (const foto of carro.fotos) {
            try {
                await firebaseStorage.refFromURL(foto).delete()
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static async adicionarCarro(carro: CarroModel) {
        if (carro.marca.id == null) {
            carro.marca.id = (await this.adicionarMarca(carro.marca)) as string;
        }
        if (carro.modelo.id == null) {
            carro.modelo.id = (await this.adicionarModelo(carro.modelo)) as string;
        }
        carro.fotos = await this.enviarFoto(carro.fotos);
        if (carro.id == null) {
            carro.id = this.makeid();
            firebaseDatabase.collection('carros').add({...carro, deleted: false});
        } else {
            let docs = await firebaseDatabase.collection('carros').where('id', '==', carro.id).get();
            if (docs.size == 1) {
                for (const doc of docs.docs) {
                    try {
                        await doc.ref.set({...carro, deleted: false});
                    } catch (err) {
                        console.error(err)
                    }
                }
            } else {
                throw new Error('Id não é único');
            }
        }
        return carro.id;

    }

    public static async adicionarMarca(marca: MarcaModel) {
        const naoExisteBanco = await (await firebaseDatabase.collection('marca').where('nome', '==', marca.nome).get()).empty
        if (naoExisteBanco) {
            marca.id = this.makeid();
            firebaseDatabase.collection('marcas').add(marca);
            return marca.id;
        }
    }

    public static async adicionarModelo(modelo: ModeloModel) {
        const naoExisteBanco = await (await firebaseDatabase.collection('modelo').where('nome', '==', modelo.nome).get()).empty
        if (naoExisteBanco) {
            modelo.id = this.makeid();
            await firebaseDatabase.collection('modelos').add(modelo);
            return modelo.id;
        }
    }

    public static async enviarFoto(fotos: (File | string)[]): Promise<string[]> {
        let url: string[] = [];
        for (const foto of fotos) {
            if (typeof foto === 'string') {
                url.push(foto)
                continue
            } 
            await firebaseStorage.ref().child('images/carros/' + this.makeid() + foto.name).put(foto).then(async (sn) => {
                await sn.ref.getDownloadURL().then(downloadUrl => {
                    url.push(downloadUrl);
                });
            });
        }
        return url
    }

    private static makeid() {
        let length = 16;
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}