import React from 'react';
import styled from 'styled-components'

const HeaderContainer = styled.div`
    box-shadow: 0px 0px 4px #AFAFAF;
    width: 100%;
    background-color: white;
    position: fixed;
    z-index: 999;
`;

const HeaderNav = styled.nav`
    margin-left: 16.5%;

    ul {
        height: 7vh;
        align-items: center;
    }

    @media screen and (max-width:768px) {
        margin-left: 0;
    }
`

const Header = () => (
    <HeaderContainer>
        <HeaderNav>
            <div className="logo">
                <a href="/"><img id="logomarca" src="/assets/logo.png" alt="Logomarca"></img></a>
            </div>
            <ul className="nav-links">
                <li><a href="#contato">Contato</a></li>
                <li><a href="https://wa.me/5521980118484"><div className='whatsapp'>Conversar no WhatsApp</div></a></li>
            </ul>
        </HeaderNav>
    </HeaderContainer>)

export default Header;