import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import { Gallery } from "../../components/Gallery";
import Header from "../../components/Header";
import CarroModel from "../../models/Carro";
import CarService from "../../services/CarService";

const CarDetail: React.FC = () => {
  const [carro, setCarro] = useState<CarroModel>();
  const { id: carId } = useParams() as any;

  useEffect(() => {
    CarService.recuperarCarro(carId).then((carro: CarroModel | undefined) =>
      setCarro(carro as CarroModel)
    );
  }, [carId]);

  return (
    <div className="pageContainer">
      <Header />
      <div className="car-detail-container">
        <div className="car-detail-title-container">
          <div className="car-detail-title">{carro?.marca.nome} {carro?.modelo.nome} {carro?.ano}</div>
        </div>
        <div className="car-detail-info-container">
          <div className="car-detail-carrousel-container">
            { carro && <Gallery images={[...carro.fotos]}/> }
          </div>
          <div className="car-detail-info-data">
            <div className="car-detail-info-item-label">Marca</div>
            <div className="car-detail-info-item-data">{carro?.marca.nome}</div>
            <div className="car-detail-info-item-label">Modelo</div>
            <div className="car-detail-info-item-data">{carro?.modelo.nome}</div>
            <div className="car-detail-info-item-label">Ano</div>
            <div className="car-detail-info-item-data">{carro?.ano}</div>
            <div className="car-detail-info-item-label">Cor</div>
            <div className="car-detail-info-item-data">{carro?.cor ?? '-'}</div>
            <div className="car-detail-info-item-label">KM</div>
            <div className="car-detail-info-item-data">{carro?.km ?? '-'} km</div>
            <div className="car-detail-info-item-label">Sobre</div>
            <div className="car-detail-info-item-about">{carro?.sobre ?? '-'}</div>
            <div className="interest-button">
            <a target="_blank" href={`https://wa.me/5521980118484?text=Ola,%20Tenho%20interesse%20no%20carro%20${carro?.marca.nome}%20${carro?.modelo.nome}%20${carro?.ano}`}>
               Estou interessado
            </a>
        </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CarDetail;
