import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import CarDetail from "./pages/site/CarDetail";
const lazy = (React as any).lazy
const Suspense = (React as any).Suspense
const LoginPage = lazy(() => import('./pages/admin/LoginPage'));
const Home = lazy(() => import('./pages/site/Home'));
const AdminPage = lazy(() => import('./pages/admin/AdminPage'));

const App: React.FC = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/admin/login">
          <LoginPage />
        </Route>
        <Route path="/admin">
          <AdminPage />
        </Route>
        <Route exact={false} path="/carro/:id">
          <CarDetail />
        </Route>
        <Route exact={true} path="/">
          <Home />
        </Route>
        <Route path="*">
          <Redirect to={"/"} />
        </Route>
      </Switch>
    </Suspense>
  </Router>
);

export default App;