
import React, { useCallback, useState } from "react";
import styled from "styled-components";

const GalleryContainer = styled.div`
    display: block;
    width: 100%;
    `;
const MainImageContainer = styled.div`
    display: flex;
    
`;
const ThumbnailsContainer = styled.div`
    margin-top: 15px;
    width: 95%;
    overflow-x: auto;
    display: flex;
    margin-left: 2.5%;
    margin-right: 2.5%;
    background-color: #e9e9e9;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
  
    scrollbar-color: #8a8a8a #d6d6d6;

    &::-webkit-scrollbar {
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #d6d6d6;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #8a8a8a;
        border-radius: 10px;
    }
`;
const Image = styled.img`
    object-fit: contain;
    width: calc(100% - 70px);
    padding: 10px;
    height: 50vh;
`;
const ThumbnailImage = styled.img`
    object-fit: contain;
    width: 20%;
    height: 100px;
    margin-right: 20px;
`;
const GalleryArrows = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    font-size: 2em;
    color: #606060;
    opacity: 0.5;
    cursor: pointer;
`;

export interface GalleryProps {
    images: string[]
}
export const Gallery: React.FC<GalleryProps> = ({ images }: GalleryProps) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0)

    const moveLeft = useCallback(() => {
        if(currentImageIndex <= 0)
            setCurrentImageIndex(0)
        else
            setCurrentImageIndex(currentImageIndex  - 1)
    }, [currentImageIndex, setCurrentImageIndex])

    const moveRight = useCallback(() => {
        if(currentImageIndex >= images.length - 1)
            setCurrentImageIndex(images.length - 1)
        else
            setCurrentImageIndex(currentImageIndex  + 1)
    }, [images, currentImageIndex, setCurrentImageIndex])

    const goToImage = useCallback((index: number) => {
        setCurrentImageIndex(index)
    }, [setCurrentImageIndex])

    return (
    <GalleryContainer>
        <MainImageContainer>
            <GalleryArrows onClick={moveLeft}>🡸</GalleryArrows>
            <Image src={images[currentImageIndex]} />
            <GalleryArrows onClick={moveRight}>🢂</GalleryArrows>
        </MainImageContainer>
        <ThumbnailsContainer>
            {
                images.map((img, index) => <ThumbnailImage onClick={() => goToImage(index)} src={img} />)
            }
        </ThumbnailsContainer>
    </GalleryContainer>)
    ;
};