import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_MESSAGING_APP_ID,
  measurementId: process.env.REACT_APP_MESSAGING_MEASUREMENT_ID
};

export const firebaseInstance: firebase.app.App = firebase.initializeApp(config);
export const firebaseDatabase: firebase.firestore.Firestore = firebase.firestore();
export const firebaseAuth: firebase.auth.Auth = firebase.auth();
export const firebaseStorage: firebase.storage.Storage = firebase.storage();
