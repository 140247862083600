import React from 'react';


const Footer = () => (
    <div className="footerContainer">
    <div className="footer-item-wrapper quem-somos">
      <p>QUEM SOMOS</p>
      <hr></hr>
      <div>
        <p>
          SVX Multimarcas é uma loja de automóveis em Niterói-RJ desde 2005.
          <br />
          Nosso objetivo é servir nossos clientes com a melhor qualidade de atendimento
          seja quem for.
          <br />
          Trabalhamos tanto com carros 0km, quanto com carros seminovos.
          <br />
          <b>Venha nos procurar!</b>
      </p>
      </div>
    </div>
    <div className="localizacao-contato">
      <div className="footer-item-wrapper localizacao">
        <p>LOCALIZAÇÃO</p>
        <hr></hr>
        <iframe id="localizacao-iframe" height="200" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ06Ha84iFmQARGbg4U3sF1vA&key=AIzaSyAppZd-jPVNkBO-X3k-xZQYcalh59SAa68"></iframe>
      </div>
      <div id="contato" className="footer-item-wrapper contato">
        <p>CONTATO</p>
        <hr></hr>
        <div className="contato-content">
          Telefone:<p>(21) 98011-8484</p>
          WhatsApp:<p>(21) 98011-8484</p>
        </div>
      </div>
    </div>
    <div className="footer-item-wrapper menu">
      <p>MENU</p>
      <hr></hr>
      <div className="footer-menu-links">
        <span><a href="#">Página Principal</a></span>
        <span><a href="#contato">Contato</a></span>
      </div>
      <div className="logo">
        <img id="logomarca" src="/assets/logo.png" alt="Logomarca"></img>
      </div>
    </div>

  </div>
    )

export default Footer;